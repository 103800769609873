import {fetchData} from "../CommonActions";
import fetch from "cross-fetch";
import {API_URL} from "../obsvcClient";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCESS = "LOGIN_SUCESS";
export const REQUEST_ISSUERS = "REQUEST_ISSUERS";
export const RECEIVE_ISSUERS = "RECEIVE_ISSUERS";
export const UPDATE_IS_ACTIVE_ISSUERS = "UPDATE_IS_ACTIVE_ISSUERS";
export const REQUEST_CATEGORIES = "REQUEST_CATEGORIES";
export const RECEIVE_CATEGORIES = "RECEIVE_CATEGOREIS";
export const REQUEST_MARKETS = "REQUEST_MARKETS";
export const RECEIVE_MARKETS = "RECEIVE_MARKETS";
export const REQUEST_NEWS = "REQUEST_NEWS";
export const RECEIVE_NEWS = "RECEIVE_NEWS";
export const REQUEST_NEWS_QUERY = "REQUEST_NEWS_QUERY";
export const RECEIVE_NEWS_QUERY = "RECEIVE_NEWS_QUERY";
export const REQUEST_NEWS_MESSAGE = "REQUEST_NEWS_MESSAGE";
export const RECEIVE_NEWS_MESSAGE = "RECEIVE_NEWS_MESSAGE";
export const RECEIVE_NEWS_MESSAGE_FAILED = "RECEIVE_NEWS_MESSAGE_FAILED";
export const CLOSE_NEWS_MESSAGE = "CLOSE_NEWS_MESSAGE";
export const NEWS_IS_RENDERED = "NEWS_IS_RENDERED";
export const NEWS_POLLING_STARTED = "NEWS_POLLING_STARTED";
export const STOPPING_NEWS_POLLING = "STOPPING_NEWS_POLLING";
export const OPEN_ADVANCED_SEARCH = "OPEN_ADVANCED_SEARCH";
export const CLOSE_ADVANCED_SEARCH = "CLOSE_ADVANCED_SEARCH";
export const RESET_DAY_PICKER_TRUE = "RESET_DAY_PICKER_TRUE";
export const RESET_DAY_PICKER_FALSE = "RESET_DAY_PICKER_FALSE";
export const OPEN_DISCLAIMER_MODAL = "OPEN_DISCLAIMER_MODAL";
export const CLOSE_DISCLAIMER_MODAL = "CLOSE_DISCLAIMER_MODAL";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const REQUEST_INFO_TEXT = "REQUEST_INFO_TEXT";
export const RECEIVE_INFO_TEXT = "RECEIVE_INFO_TEXT";
export const RECEIVE_INFO_TEXT_FAILED = "RECEIVE_INFO_TEXT_FAILED";
export const PAGE_NEWS = "PAGE_NEWS";
export const FAILED_FETCHING_LIST = "FAILED_FETCHING_LIST";
export const DISPATCH_CONFIG = "DISPATCH_CONFIG";

export function fetchQuery(values) {
  function requestQuery() {
    return { type: REQUEST_NEWS_QUERY };
  }
  function receieveQuery(data) {
    return {
      type: RECEIVE_NEWS_QUERY,
      news: data.messages,
      overflow: data.overflow,
    };
  }

  return (dispatch) => {
    const valueOrEmpty = (value) => {
      if (value === undefined) return "";
      else return value;
    };

    let command = "/v1/newsreader/customQuery";
    if (values !== undefined) {
      command =
        command +
        "?action=" +
        valueOrEmpty(values.action) +
        "&symbol=" +
        valueOrEmpty(values.symbol);
    }

    return dispatch(
      fetchData(
        {
          request: () => dispatch(requestQuery()),
          receive: (data) => dispatch(receieveQuery(data)),
          receiveFail: (error) => dispatch(showErrorMessage(error)),
          obsvcFilename: command,
          params: {
            action: valueOrEmpty(values.action),
            symbol: valueOrEmpty(values.symbol),
          },
        },
        false
      )
    );
  };
}

export function fetchNews(params) {
  function requestNews() {
    return { type: REQUEST_NEWS };
  }
  function receiveNews(data) {
    return {
      type: RECEIVE_NEWS,
      news: data.messages,
      overflow: data.overflow,
    };
  }

  return (dispatch) => {
    let command = "/v1/newsreader/list";
    if (params) {
      command += "?" + params;
    }

    dispatch(
      fetchData(
        {
          request: () => dispatch(requestNews()),
          receive: (data) => dispatch(receiveNews(data)),
          receiveFail: (error) => dispatch(showErrorMessage(error)),
          obsvcFilename: command,
        },
        false
      )
    );
  };
}

export function fetchNewsMessage(messageId, history, useClientAnnouncementId) {
  function requestNewsMessage() {
    return { type: REQUEST_NEWS_MESSAGE };
  }
  function receiveNewsMessage(data) {
    if (history !== undefined)
      history.push(
        (useClientAnnouncementId ? "/announcement/" : "/message/") + messageId
      );
    return {
      type: RECEIVE_NEWS_MESSAGE,
      newsMessage: data?.message,
      textFormat: data?.textFormat,
    };
  }
  function receiveNewsMessageFailed() {
    return { type: RECEIVE_NEWS_MESSAGE_FAILED };
  }

  return (dispatch) => {
    dispatch(
      fetchData(
        {
          request: () => dispatch(requestNewsMessage()),
          receive: (result) => dispatch(receiveNewsMessage(result)),
          receiveFail: () => {
            history.push("/notfound");
            return dispatch(receiveNewsMessageFailed());
          },
          obsvcFilename:
            (useClientAnnouncementId
              ? "/v1/newsreader/announcement?clientAnnouncementId="
              : "/v1/newsreader/message?messageId=") + messageId,
        },
        false
      )
    );
  };
}

export function newsIsRendered() {
  return { type: NEWS_IS_RENDERED };
}

export function fetchListData(obsvc, request_type, receive_type, timeOutInSeconds) {
  return (dispatch) => {
    dispatch(requestListData());

    return dispatch(
      fetchData(
        {
          request: () => dispatch(requestListData()),
          receive: (items) => dispatch(receiveListData(items)),
          receiveFail: () => dispatch(receiveListDataFailed()),
          obsvcFilename: obsvc,
        },
        false,
        timeOutInSeconds
      )
    );
  };

  function requestListData() {
    return { type: request_type };
  }
  function receiveListData(items) {
    return {
      type: receive_type,
      items,
    };
  }
  function receiveListDataFailed() {
    return { type: FAILED_FETCHING_LIST };
  }
}

export function fetchCategories() {
  return fetchListData(
    "/v1/newsreader/categories",
    REQUEST_CATEGORIES,
    RECEIVE_CATEGORIES
  );
}

export function fetchIssuers() {
  return fetchListData(
    "/v1/newsreader/issuers",
    REQUEST_ISSUERS,
    RECEIVE_ISSUERS
  );
}

export const FetchEnv = {
  request: "REQUEST_ENV",
  receive: "RECEIVE_ENV",
  receiveFail: "RECEIVE_ENV_FAILED",
};
export const fetchEnv = () => {
    return fetchListData(
        "/v1/newsreader/env",
        FetchEnv.request,
        FetchEnv.receive,
        30
    )};

export function fetchMarkets() {
  return fetchListData(
    "/v1/newsreader/markets",
    REQUEST_MARKETS,
    RECEIVE_MARKETS
  );
}

export function updateActiveIssuers(isActive) {
  return { type: UPDATE_IS_ACTIVE_ISSUERS, isActive };
}

export function openAdvancedSearch() {
  return { type: OPEN_ADVANCED_SEARCH };
}

export function closeAdvancedSearch() {
  return { type: CLOSE_ADVANCED_SEARCH };
}

export function resetDayPickerTrue() {
  return { type: RESET_DAY_PICKER_TRUE };
}

export function resetDayPickerFalse() {
  return { type: RESET_DAY_PICKER_FALSE };
}

export function openDisclaimerModal() {
  return { type: OPEN_DISCLAIMER_MODAL };
}

export function closeDisclaimerModal() {
  return { type: CLOSE_DISCLAIMER_MODAL };
}

export function changeLanguage() {
  return { type: CHANGE_LANGUAGE };
}

export function showErrorMessage(errorMessage) {
  return { type: SHOW_ERROR_MESSAGE, errorMessage };
}

export function fetchInfoTextFromServer() {
  function requestInfoTextFromServer() {
    return { type: REQUEST_INFO_TEXT };
  }
  function receiveInfoTextFromServer(data) {
    return {
      type: RECEIVE_INFO_TEXT,
      dataEN: data.en,
      dataNB: data.nb,
    };
  }
  function receiveInfoTextFromServerFailed() {
    return { type: RECEIVE_INFO_TEXT_FAILED };
  }

  return (dispatch) => {
    dispatch(
      fetchData(
        {
          request: () => dispatch(requestInfoTextFromServer()),
          receive: (result) => receiveInfoTextFromServer(result),
          receiveFail: () => dispatch(receiveInfoTextFromServerFailed()),
          obsvcFilename: "/v1/newsreader/infotext",
        },
        false
      )
    );
  };
}

export function pageNewsList(activePage) {
  return { type: PAGE_NEWS, activePage };
}

export function fetchConfig() {
  return fetch("/env.json", {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export const FetchAttachment = {
    request: "REQUEST_ATTACHMENT",
    receive: "RECEIVE_ATTACHMENT",
    receiveFail: "RECEIVE_ATTACHMENT_FAILED",
};
export function fetchAttachment(url) {
    function requestAttachment() {
        return { type: FetchAttachment.request };
    }
    function receiveAttachment(data) {
        return {
            type: FetchAttachment.receive,
            file: data.payload,
            overflow: data.overflow,
        };
    }

    return (dispatch) => {
        return dispatch(
            fetchData(
                {
                    request: () => dispatch(requestAttachment()),
                    receive: (data) => dispatch(receiveAttachment(data)),
                    receiveFail: (error) => dispatch(showErrorMessage(error)),
                    obsvcFilename: url,
                },
                false
            )
        );
    };
};

export const FetchPDF = {
    request: "REQUEST_PDF",
    receive: "RECEIVE_PDF",
    receiveFail: "RECEIVE_PDF_FAILED",
};
export function fetchPDF(url) {
    function requestPDF() {
        return { type: FetchPDF.request };
    }
    function receivePDF(data) {
        return {
            type: FetchPDF.receive,
            file: data.payload,
            overflow: data.overflow,
        };
    }

    return (dispatch) => {
        return dispatch(
            fetchData(
                {
                    request: () => dispatch(requestPDF()),
                    receive: (data) => dispatch(receivePDF(data)),
                    receiveFail: (error) => dispatch(showErrorMessage(error)),
                    obsvcFilename: url,
                },
                false
            )
        );
    };
};

export const FetchApiUrl = {
    request: "REQUEST_API_URL",
    receive: "RECEIVE_API_URL",
    receiveFail: "RECEIVE_API_URL_FAILED",
}

export function fetchApiUrl() {
    function requestApiUrl() {
        return {type: FetchApiUrl.request}
    }

    function receiveApiUrl(api) {
        return {
            type: FetchApiUrl.receive,
            data: api
        };
    }

    return (dispatch) => {
        dispatch(requestApiUrl());

        if (sessionStorage.getItem('api')) {
            return dispatch(receiveApiUrl(sessionStorage.getItem('api')))
        } else {
            return API_URL()
                .then(api => dispatch(receiveApiUrl(api)))
                .catch(error => dispatch(showErrorMessage(error)));
        }
    }
}
