import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import NewsMessage from "../components/NewsMessage";
import moment from "moment-timezone";
import {fetchNewsMessage, fetchPDF, fetchApiUrl} from "../actions/actions";
import { StyledNewsMessage } from "../components/styled-components/StyledNewsMessage";
import { withTranslation } from "react-i18next";
import { getLanguage } from "../js/utils";
import { getMarketString } from "../marketUtil";
import {FlexContainer} from "../components/FlexContainer";
import {OverlayLoader} from "../components/Loader";

class NewsMessageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.checkOrientation = this.checkOrientation.bind(this);
    this.state = {
      showMessageInFullSize: false,
      width: 0,
      height: 0,
      loading: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  checkOrientation() {
    this.setState({ orientationState: window.orientation });
  }

  componentDidMount() {
    window.addEventListener("orientationchange", this.checkOrientation, false);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    if (!this.props.urls.api_large) {
      this.props.dispatch(fetchApiUrl())
    }

    if (!this.props.newsMessageIsFetched && !this.props.isFetchingNewsMessage)
      this.props.dispatch(
        fetchNewsMessage(
          this.props.match.params.id,
          this.props.history,
          this.props.useClientAnnouncementId
        )
      );
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { newsMessage, mapMarkets, t } = this.props;

    if (this.props.newsMessageIsFetched && newsMessage != null) {
      const formatedTime = moment(newsMessage.publishedTime).format(
        "DD.MM.YYYY, HH:mm:ss"
      );
      const tz = moment.tz.guess();
      const category = newsMessage.category[0]["category_" + getLanguage()];
      const issuer = newsMessage.issuerName;

      const viewConfig =
        this.props.config &&
        this.props.config.markets &&
        this.props.config.markets.newsView
          ? this.props.config.markets.newsView
          : ["symbol"];

      const markets =
        mapMarkets &&
        mapMarkets.size > 0 &&
        newsMessage.markets &&
        newsMessage.markets.length === 1
          ? getMarketString(mapMarkets.get(newsMessage.markets[0]), viewConfig)
          : mapMarkets && mapMarkets.size > 0 && newsMessage.markets
          ? newsMessage.markets.reduce((acc, market) => {
              //Sometimes this is rendered before markets are fetched therefore it crashes
              if (acc && acc.length > 0)
                return (
                  acc +
                  ", " +
                  getMarketString(mapMarkets.get(market), viewConfig)
                );
              else return getMarketString(mapMarkets.get(market), viewConfig);
            }, "")
          : "";

      const messageInfoList = () => {
        var arrMessageInfo = [];
        if (newsMessage.infoRequired === 1)
          arrMessageInfo.push(
            <StyledNewsMessage.CategoryLi
              key={0}
              type={"infoRequired"}
              tooltipText={t("infoRequired_tooltip")}
            >
              {t("infoRequired")}
            </StyledNewsMessage.CategoryLi>
          );
        if (newsMessage.oamMandatory === 1)
          arrMessageInfo.push(
            <StyledNewsMessage.CategoryLi
              key={1}
              type={"oamMandatory"}
              tooltipText={t("oamMandatory_tooltip")}
            >
              {t("oamMandatory")}
            </StyledNewsMessage.CategoryLi>
          );

        return arrMessageInfo;
      };

      const showFileFromServer = (blob, fileName) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.href = data;
        link.download = fileName;
        link.dispatchEvent(
            new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window })
        );
      };

      const downloadPDF = (filename, url) => {
        this.props.dispatch(fetchPDF(url))
            .then(data => {
              showFileFromServer(data, filename)
            })
            .catch((err) => {
              console.log(err)
            })
      }

      const fileList = (newsMessage.attachments || []).map((file, index) => {
        return (
        <StyledNewsMessage.FileLi
          key={index}
          href={this.props.urls.api_large + "/v1/newsreader/attachment?messageId=" + newsMessage.messageId + "&attachmentId=" + file.id}
          download={file.name}
          onlyPDFIcon={this.props.onlyPDFIcon}
        >
          {file.name.replace(/_/g, " ")}
        </StyledNewsMessage.FileLi>
      )
      });

      const correctedVersions = () => {
        var arrCorrections = [];
        if (newsMessage.correctedByMessageId > 0)
          arrCorrections.push(
            <StyledNewsMessage.DecoratedText
              key="1"
              onClick={() =>
                this.props.dispatch(
                  fetchNewsMessage(
                    newsMessage.correctedByMessageId,
                    this.props.history
                  )
                )
              }
            >
              {t("correctedBy_link_text")}
            </StyledNewsMessage.DecoratedText>
          );
        if (newsMessage.correctionForMessageId > 0)
          arrCorrections.push(
            <StyledNewsMessage.DecoratedText
              key="2"
              onClick={() =>
                this.props.dispatch(
                  fetchNewsMessage(
                    newsMessage.correctionForMessageId,
                    this.props.history
                  )
                )
              }
            >
              {t("correctionFor_link_text")}
            </StyledNewsMessage.DecoratedText>
          );
        return arrCorrections;
      };

      return (
          <FlexContainer>
            {this.state.loading ? <OverlayLoader /> : undefined}
            <NewsMessage
                publishedTime={formatedTime}
                issuer={issuer}
                issuerId={newsMessage.issuerSign}
                instrumentName={newsMessage.instrumentName}
                fileList={fileList}
                printLink={() => downloadPDF(newsMessage.title + ".pdf", "/v1/newsreader/pdf?messageId=" + newsMessage.messageId + "&language=" + getLanguage() + "&tz=" + tz)}
                messageInfoList={messageInfoList()}
                markets={markets}
                messageId={newsMessage.messageId}
                showMessageInFullSize={this.state.showMessageInFullSize}
                orientationState={this.state.orientationState}
                width={this.state.width}
                height={this.state.height}
                toggleMessageInFullSize={() =>
                    this.setState({
                      showMessageInFullSize: !this.state.showMessageInFullSize,
                    })
                }
                title={newsMessage.title}
                body={newsMessage.body}
                category={category}
                advancedSearchIsOpen={this.props.advancedSearchIsOpen}
                hasCorrections={correctedVersions().length > 0}
                correctedVersionsLinks={correctedVersions()}
                hasMultipleLanguages={false} //TODO: Implement this when it is available backend
                otherLanguageLink={""} //TODO: Implement this when it is available backend
                textFormat={newsMessage.textFormat}
            />
          </FlexContainer>
      );
    }
    return "";
  }
}

NewsMessageContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    newsMessage,
    issuer,
    market,
    language,
    advancedSearchToggler,
    envJson,
    urls,
  } = state;

  return {
    newsMessage: newsMessage.newsMessage,
    isFetchingNewsMessage: newsMessage.fetchingMessage,
    newsMessageIsFetched: newsMessage.newsMessageIsFetched,
    advancedSearchIsOpen: advancedSearchToggler.isOpen,
    mapIssuer: issuer.mapIssuers,
    mapMarkets: market.mapMarkets,
    language: language,
    textFormat: newsMessage.textFormat,
    config: envJson.env,
    urls: urls
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(NewsMessageContainer)
);
